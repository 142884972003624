import React from 'react'
// import Header from '../components/Header'
import Hero from '../components/Hero'
import Features from '../components/Features'
import LogoClouds from '../components/LogoClouds'
import ContactUs from '../components/ContactUs'
import Footer from '../components/Footer'

const Home = () => {
  return (
    <div className='flex flex-col min-h-screen'>
      {/* <Head nonce>
        <title>Zeiger Software Solutions</title>
        <meta
          name='description'
          content='Zeiger Software Solutions specializes in custom solutions that are a cut above the rest and allow our customers to get results. Our solutions let you focus on what matters - your business.'
        />
        <meta name='viewport' content='width=device-width, initial-scale=1' />
        <meta name='robots' content='index, follow' />
      </Head> */}
      {/* <Header /> */}
      <Hero />
      <Features />
      <LogoClouds />
      <ContactUs />
      <div className='flex-grow' />
      <Footer />
    </div>
  )
}

export default Home
