import validator from 'validator'

export const contactFormValidations = {
  firstName: { fn: (v) => validator.isEmpty(v), message: 'First name is required' },
  lastName: { fn: (v) => validator.isEmpty(v), message: 'Last name is required' },
  company: { fn: (v) => validator.isEmpty(v), message: 'Company is required' },
  email: { fn: (v) => !validator.isEmail(v), message: 'A valid email is required' },
  phoneNumber: { fn: (v) => !validator.isMobilePhone(v, 'en-US'), message: 'A valid phone number is required' },
  message: { fn: (v) => validator.isEmpty(v), message: 'Message is required' }
}

export const applicationFormValidations = {
  name: { fn: (v) => validator.isEmpty(v), message: 'Name is required' },
  email: { fn: (v) => !validator.isEmail(v), message: 'A valid email is required' },
  phone: { fn: (v) => !validator.isMobilePhone(v, 'en-US'), message: 'A valid phone number is required' },
  about: { fn: (v) => validator.isEmpty(v), message: 'About is required' },
  salary: { fn: (v) => validator.isEmpty(v), message: 'Salary is required' },
  country: { fn: (v) => validator.isEmpty(v), message: 'Country is required' },
  region: { fn: (v) => validator.isEmpty(v), message: 'Region is required' },
  resumeFile: { fn: (v) => v === '', message: 'Resume is required' }
}

export const validateForm = (formData, formValidations) => {
  const errors = {}
  for (let key in formData) {
    if (!formValidations[key]) continue
    const { fn, message } = formValidations[key]
    if (fn(formData[key])) errors[key] = message
  }
  return errors
}
