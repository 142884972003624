import React from 'react'

/*
Salesforce, Workday, stripe, Twilio, Flex, Node.js, JS, React, PostgreSQL, MongoDB
*/

export default function LogoClouds() {
  return (
    <div className='bg-white'>
      <div className='max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8'>
        <div className='mt-6 grid grid-cols-2 gap-0.5 md:grid-cols-3 lg:mt-8'>
          <div className='col-span-1 flex justify-center py-8 px-8 bg-gray-50'>
            <img className='max-h-12' src='/javascript.png' alt='JavaScript' />
          </div>
          <div className='col-span-1 flex justify-center py-8 px-8 bg-gray-50'>
            <img className='max-h-12' src='/react.png' alt='React' />
          </div>
          <div className='col-span-1 flex justify-center py-8 px-8 bg-gray-50'>
            <img className='max-h-10' src='/nextjs.png' alt='Nextjs' />
          </div>
          <div className='col-span-1 flex justify-center py-8 px-8 bg-gray-50'>
            <img className='max-h-12' src='/nodejs.png' alt='Nodejs' />
          </div>
          <div className='col-span-1 flex justify-center py-8 px-8 bg-gray-50'>
            <img className='max-h-12' src='/postgresql.png' alt='PostgreSQL' />
          </div>
          <div className='col-span-1 flex justify-center py-8 px-8 bg-gray-50'>
            <img className='max-h-12' src='docker.webp' alt='Docker' />
          </div>
        </div>
      </div>
    </div>
  )
}
