import React from 'react'
import { CheckIcon } from '@heroicons/react/20/solid'

// const features = [
//   {
//     name: 'Workday',
//     description: 'On-demand financial management, human capital management and Student information system software. Specializing in enterprise Interface Builder (EIB), Workday Extend, Workday Studio and Workday Web Services integrations.'
//   },
//   { name: 'Salesforce', description: 'Industry leading customer relationship management software. One of the reasons why people love using Salesforce is that it is very easy to customize. Our Salesforce consultants are here to assist you through all the great ideas you have.' },
//   {
//     name: 'Stripe',
//     description: 'Payments infrastructure for the internet. We can help you build a payment gateway that accepts online payments globally. Including custom in-field solutions.'
//   },
//   { name: 'Twilio', description: 'Voice infrastructure for the internet. Programmable voice and sms tools. Scalable contact center solutions.' },
//   { name: 'Custom Solutions', description: 'Are the delivered features just not enough? We will help you explore all of your options. Custom software development is our specialty!' },
//   { name: 'Project Managers', description: 'Let us help you provide leadership and direction to your next project.' },
//   { name: 'Software Engineers', description: 'Do you need one of these but not sure what type? We can help!' },
//   { name: 'UI/UX Designers', description: 'Our designers will assist your team to create user-friendly interfaces that enable users to understand how to use complex technical products.' }
// ]

const features = [
  { name: 'Cloud Computing Leadership', description: 'Harness the potential of cloud computing with our expertise in AWS and GCP, ensuring scalable, secure, and reliable solutions to drive digital transformation and efficiency in your operations.' },
  { name: 'Innovative Technology Stack', description: 'Explore the forefront of technology with our diverse stack, integrating the latest tools and frameworks to deliver forward-thinking solutions. Stay ahead in the digital landscape with our commitment to innovation and excellence.' },
  {
    name: 'Workday Integration',
    description: 'Seamlessly incorporate Workday, a leading cloud-based human resources and finance management platform, into your business processes for enhanced workforce management and financial efficiency.'
  },
  { name: 'Salesforce Expertise', description: 'Leverage the power of Salesforce, a robust customer relationship management (CRM) platform, to streamline your sales, marketing, and customer service operations, driving business growth and customer satisfaction.' },
  {
    name: 'Stripe Payment Solutions',
    description: 'Optimize online payment processes with Stripe, a secure and efficient payment gateway, ensuring smooth transactions and providing a reliable payment experience for your customers.'
  },
  { name: 'Twilio Communication Integration', description: 'Enhance communication channels by integrating Twilio\'s cloud communications platform, enabling seamless and effective messaging, voice, and video interactions with your clients and stakeholders.' },
  { name: 'Custom Software Solutions', description: 'Tailor-made software solutions designed to meet your specific business needs, providing a personalized and scalable approach to address unique challenges and drive innovation.' },
  { name: 'Project Management Excellence', description: 'Benefit from experienced project managers who bring efficiency and organization to your projects, ensuring timely delivery, effective resource allocation, and successful execution from inception to completion.' },
  { name: 'Skilled Software Engineers', description: 'Tap into a pool of highly skilled software engineers with expertise in various technologies, capable of developing robust and scalable solutions that align with your business objectives.' },
  { name: 'UI/UX Design Innovation', description: 'Elevate user experiences with the expertise of UI/UX designers who craft visually appealing and intuitive interfaces, enhancing usability and leaving a lasting impression on your audience.' }
]

export default function Features() {
  return (
    <div className='bg-white'>
      <div className='max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:py-24 lg:px-8 lg:grid lg:grid-cols-3 lg:gap-x-8'>
        <div>
          <h2 className='text-base font-semibold text-emerald-600 uppercase tracking-wide'>Everything you need</h2>
          <h3 className='mt-2 text-3xl font-extrabold text-gray-900'>All-in-one solution</h3>
          <p className='mt-4 text-lg text-gray-500'>
            We will solve all of your technology pain points.
          </p>
        </div>
        <div className='mt-12 lg:mt-0 lg:col-span-2'>
          <dl className='space-y-10 sm:space-y-0 sm:grid sm:grid-cols-2 sm:grid-rows-5 sm:grid-flow-col sm:gap-x-6 sm:gap-y-10 lg:gap-x-8'>
            {features.map((feature) => (
              <div key={feature.name}>
                <dt className='flex'>
                  <CheckIcon className='h-6 w-6 text-green-500 z-1' aria-hidden='true' />
                  <p className='ml-3 text-lg leading-6 font-medium text-gray-900'>{feature.name}</p>
                </dt>
                <dd className='mt-2 ml-9 text-base text-gray-500'>{feature.description}</dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  )
}
