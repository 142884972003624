import React from 'react'

const getClassName = (error) => error
  ? 'py-3 px-4 border-2 block w-full shadow-sm focus:ring-emerald-500 focus:border-emerald-500 focus:outline-emerald-500 border-red-600 rounded-md'
  : 'py-3 px-4 block w-full shadow-sm focus:ring-emerald-500 focus:border-emerald-500 border-gray-300 rounded-md border-2 focus:outline-emerald-500'

const labelClassName = 'block text-sm font-medium text-gray-700'

export const Input = ({ id, label = '', value = '', error = null, handleChange, ...rest }) => {
  const errorState = error !== '' && error !== null && error !== undefined
  const className = getClassName(errorState)

  return (
    <div>
      <label htmlFor={id} className={labelClassName}>
        {label}
      </label>
      <div className='mt-1'>
        <input
          type='text'
          name={id}
          id={id}
          className={className}
          value={value}
          onChange={handleChange}
          { ...rest }
        />
        <span
          className='text-xs text-red-600'
        >
          {
            error ? `* ${error}` : ''
          }
        </span>
      </div>
    </div>
  )
}

export const Select = ({ id, label = '', options = [], value = '', error = null, handleChange, ...rest }) => {
  const errorState = error !== '' && error !== null && error !== undefined
  const className = getClassName(errorState)

  return (
    <div>
      <label htmlFor={id} className={labelClassName}>
        {label}
      </label>
      <div className='mt-1'>
        <select
          name={id}
          id={id}
          className={className}
          value={value}
          onChange={handleChange}
          { ...rest }
        >
          {options.map((option) => (
            <option key={option}>{option}</option>
          ))}
        </select>
        <span
          className='text-xs text-red-600'
        >
          {
            error ? `* ${error}` : ''
          }
        </span>
      </div>
    </div>
  )
}

export const TextArea = ({ id, label = '', value = '', error = null, handleChange, ...rest }) => {
  const errorState = error !== '' && error !== null && error !== undefined
  const className = getClassName(errorState)

  return (
    <div>
      <label htmlFor={id} className={labelClassName}>
        {label}
      </label>
      <div className='mt-1'>
        <textarea
          type='text'
          name={id}
          id={id}
          className={className}
          value={value}
          onChange={handleChange}
          { ...rest }
        />
        <span
          className='text-xs text-red-600'
        >
          {
            error ? `* ${error}` : ''
          }
        </span>
      </div>
    </div>
  )
}

export const FileInput = ({ id, label = '', error = null, handleChange, ...rest }) => {
  const errorState = error !== '' && error !== null && error !== undefined
  const className = errorState
    ? 'focus:outline-emerald-500 rounded-md border-2 border-red-600 w-full text-sm text-gray-500 file:mr-4 file:py-3 file:px-4 file:border-0 file:text-sm file:font-semibold file:bg-emerald-50 file:text-emerald-700 hover:file:bg-emerald-100'
    : 'focus:outline-emerald-500 rounded-md border border-gray-300 w-full text-sm text-gray-500 file:mr-4 file:py-3 file:px-4 file:border-0 file:text-sm file:font-semibold file:bg-emerald-50 file:text-emerald-700 hover:file:bg-emerald-100'

  return (
    <div>
      <label htmlFor={id} className={labelClassName}>
        {label}
      </label>
      <div className='mt-1'>
        <input
          type='file'
          name={id}
          id={id}
          className={className}
          onChange={handleChange}
          { ...rest }
        />
        <p
          className='text-xs text-red-600'
        >
          {
            error ? `* ${error}` : ''
          }
        </p>
      </div>
    </div>
  )
}
