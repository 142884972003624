import React from 'react'

export default function Hero() {
  const handleSubmit = (e) => {
    e.preventDefault()
    const el = document.getElementById('contact-us')
    el.scrollIntoView({ behavior: 'smooth', block: 'center' })
  }
  return (
    <div className='overflow-hidden'>
      <main>
        <div className='pt-10 bg-gray-900 sm:pt-16 lg:pt-8 lg:pb-14 lg:overflow-hidden'>
          <div className='mx-auto max-w-7xl lg:px-8'>
            <div className='lg:grid lg:grid-cols-2 lg:gap-8'>
              <div className='mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 sm:text-center lg:px-0 lg:text-left lg:flex lg:items-center'>
                <div className='lg:py-24'>
                  {/* <a
                    href='/careers'
                    className='inline-flex items-center text-white bg-black rounded-full p-1 pr-2 sm:text-base lg:text-sm xl:text-base hover:text-gray-200'
                  >
                    <span className='px-3 py-0.5 text-white text-xs font-semibold leading-5 uppercase tracking-wide bg-emerald-500 rounded-full'>
                      We're hiring
                    </span>
                    <span className='ml-4 text-sm'>Visit our careers page</span>
                    <ChevronRightIcon className='ml-2 w-5 h-5 text-gray-500' aria-hidden='true' />
                  </a> */}
                  <h1 className='mt-4 text-4xl tracking-tight font-extrabold text-white sm:mt-5 sm:text-6xl lg:mt-6 xl:text-6xl'>
                    <span className='block'>Zeiger</span>
                    <span className='block text-emerald-400'>Software</span>
                  </h1>
                  <p className='mt-3 text-base text-gray-300 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl'>
                    {/* Zeiger Software specializes in custom solutions that are a cut above the rest and allow our customers to get results. Our solutions let you focus on what matters - your business. We can help you build a system that works for you, from concept to development. */}
                    Empower your business with innovation and efficiency at
                    every step. We bring together cutting-edge solutions to
                    propel your success. From seamless integration with industry
                    leaders like Workday, Salesforce, Stripe, and Twilio, to
                    crafting custom software solutions tailored to your unique
                    needs, our expert team of project managers, software
                    engineers, and UI/UX designers ensures a comprehensive
                    approach to elevate your business to new heights. Experience
                    the difference of working with a dynamic software consulting
                    team committed to delivering excellence at every turn.
                  </p>
                  <div className='mt-10 sm:mt-12'>
                    <form
                      onSubmit={handleSubmit}
                      className='sm:max-w-xl sm:mx-auto lg:mx-0'
                    >
                      <div className='sm:flex'>
                        <button
                          type='submit'
                          className='block w-full py-3 px-4 rounded-md shadow bg-emerald-500 text-white font-medium hover:bg-emerald-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-300 focus:ring-offset-gray-900'
                        >
                          Contact us
                        </button>
                        <div className='w-full' />
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div className='mt-12 -mb-16 sm:-mb-48 lg:m-0 lg:relative'>
                <div className='mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 lg:max-w-none lg:px-0'>
                  {/* Illustration taken from Lucid Illustrations: https://lucid.pixsellz.io/ */}
                  <img
                    className='w-full lg:absolute lg:inset-y-0 lg:left-0 lg:h-full lg:w-auto lg:max-w-none'
                    src='cloud-illustration-indigo-400.svg'
                    alt='Cloud computing'
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* More main page content here... */}
      </main>
    </div>
  )
}
