import React from 'react'
import Home from './layouts/Home'
import { Helmet } from 'react-helmet'
import { Toaster } from 'react-hot-toast'

const App = () => {
  return (
    <div>
      <Helmet>
        <title>Zeiger Software</title>
        <meta charset='utf-8' />
        <link rel='icon' href='/favicon.ico' />
        <link rel='apple-touch-icon' href='/logo192.png' />
        <link rel='manifest' href='/manifest.json' />
        <link rel='canonical' href='https://www.zeigersoftware.com/' />
        <link
          rel='sitemap'
          type='application/xml'
          title='Sitemap'
          href='/sitemap.xml'
        />
        <meta name='viewport' content='width=device-width, initial-scale=1' />
        <meta name='theme-color' content='#000000' />
        <meta
          name='description'
          content='Boost productivity and efficiency with our integrated software solutions. From Workday to Salesforce, discover tailored excellence with Zeiger Software. Elevate your business today!'
        />
        <meta name='robots' content='index, follow' />
      </Helmet>
      <Home />
      <Toaster toastOptions={{
        position: 'bottom-left'
      }} />
    </div>
  )
}

export default App
