import { AsYouType, validatePhoneNumberLength } from "libphonenumber-js"

const changeHandler = (setFn) => ({ target }) => {
  const value = target?.files?.length
    ? target.files[0]
    : target.value

  const id = target.id

  // Parse phone numbers
  if (id.toLowerCase().includes('phone')) {
    if (value.length === 4) return setFn(prevState => ({ ...prevState, [id]: value }))
    const phoneNumber = new AsYouType('US').input(value)
    if (validatePhoneNumberLength(phoneNumber, 'US') === 'TOO_LONG') return
    return setFn(prevState => ({ ...prevState, [id]: phoneNumber}))
  }

  return setFn(prevState => ({ ...prevState, [id]: value }))
}

export default changeHandler
