import React from 'react'

const TextArea = ({ id, label = '', value = '', placeholder, error = null, handleChange, rows = 4 }) => {
  const errorState = error !== '' && error !== null && error !== undefined
  const errorBorder = errorState
    ? 'py-3 px-4 border-2 block w-full shadow-sm focus:ring-emerald-500 focus:border-emerald-500 border-red-600 rounded-md focus:outline-emerald-500'
    : 'py-3 px-4 block w-full shadow-sm focus:ring-emerald-500 focus:border-emerald-500 border-gray-300 rounded-md border-2 focus:outline-emerald-500'
  return (
    <div>
      <label htmlFor='firstName' className='block text-sm font-medium text-gray-700'>
        {label}
      </label>
      <div className='mt-1'>
        <textarea
          id={id}
          name={id}
          rows={rows}
          autoComplete='none'
          placeholder={placeholder}
          className={errorBorder}
          value={value}
          onChange={handleChange}
        />
        <p
          className='text-xs text-red-600'
        >
          {
            error ? `* ${error}` : ''
          }
        </p>
      </div>
    </div>
  )
}

export default TextArea
